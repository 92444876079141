import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <h1>Hello, world!</h1>
        <p>This is for now a landing page for what will be <a href='/'>amnathrig.com</a></p>
        <ul style={{ listStyleType: 'none' }}>
          <li> A description of the app can be found at <a href='https://www.f6s.com/company/amnathrig#about'>F6S</a></li>
          <li><img src='https://rosssquared.org/img/mytandt_home.png' alt='Amnathrig'/></li>
        </ul>
      </div>
    );
  }
}
